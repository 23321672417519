import Footer from "../component/layout/footer";
import Header from "../component/layout/header";
import PageHeader from "../component/layout/pageheader";
import { useAuth } from "../context/AuthContext";
import Leaderboard from "../component/section/leaderboard";

const LeaderboardPage = () => {
    const { user, logout } = useAuth();

    return (
        <>
            <Header />
            <PageHeader title={"Leaderboard"} curPage={"Leaderboard"} />
            <Leaderboard />
            <Footer />
        </>
    );
};

export default LeaderboardPage;
