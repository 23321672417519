
const PrivacyPolicies = () => {
    return (
        <section className="match-section padding-top padding-bottom" style={{backgroundImage: "url(/assets/images/match/bg-2.jpg)"}}>
            <div className="container">
                <div className="section-wrapper">
                    <div className="row g-4 justify-content-center collection-grid GameListStyle">
                        <p>We at Hitthepick.com (and our service providers listed here) are committed to protecting your privacy. This Privacy and Cookie Policy (the "Privacy Policy") outlines the information and data collected, stored, and processed by Hitthepick.com, including the information collected on our websites.</p>

                        <p>This Privacy Policy applies to your use of the Hitthepick Rewards Program, Premium Products, and other paid services offered by Hitthepick.com ("Premium Services") as a customer of Hitthepick.com.</p>

                        <p>We periodically update this Privacy Policy, and we encourage you to review it carefully before using this website. By using this website, you signify your agreement to this Privacy Policy.</p>
                        
                        <h4>What Information We Collect About You</h4>
                        <ol>
                            <li>
                                <h5>Information When You Visit Our Website</h5>
                                <p>You may explore our website without providing Personal Information. However, during your visits, we may automatically collect certain information about your device, which may qualify as Personal Data. This includes your IP address, browser type, domain names, internet service provider (ISP), operating system, clickstream data, access times, and referring website addresses.</p>
                                <p>This information helps us compile general statistics about the website's usage and improve user experience. For these purposes, we may link automatically-collected data with Personal Information like your name, email, address, and phone number. Learn more in our Cookie Section below.</p>
                            </li>
                            <li>
                                <h5>Information When You Subscribe to Our Services</h5>
                                <p>When you subscribe to our services, we may collect Personal Information, such as:</p>
                                <ol style={{ marginLeft: '100px', marginTop: '0px', marginBottom: '25px' }}>
                                    <li>Name</li>
                                    <li>Email address</li>
                                    <li>Phone number</li>
                                    <li>Payment information (e.g., credit card or bank details).</li>
                                </ol>
                                <p>We use third-party PCI-compliant service providers for payment processing. No sensitive payment information is stored directly by us.</p>
                            </li>
                            <li>
                                <h5>Information from Inquiries</h5>
                                <p>If you submit an inquiry through a contact form or email us, we may collect the information you voluntarily provide.</p>
                            </li>
                            <li>
                                <h5>Information from Third Parties</h5>
                                <p>We may receive Personal Information about you from third parties, including co-branded service providers or partners and social media platforms.</p>
                            </li>
                            <li>
                                <h5>Information About Children</h5>
                                <p>Our website is not intended for individuals under 18. If you believe we have inadvertently collected information about a child under 18, contact us at privacy@hitthepick.com, and we will delete it promptly.</p>
                            </li>
                        </ol>

                        <h4>How We Use Information</h4>
                        <p>We use your Personal Information to:</p>
                        <ol>
                            <ol style={{ marginLeft: '100px', marginTop: '-25px', marginBottom: '25px' }}>
                                <li>Provide and enhance our services.</li>
                                <li>Respond to your inquiries and requests.</li>
                                <li>Improve the website and personalize your experience</li>
                                <li>Send marketing communications, subject to your preferences.</li>
                                <li>Provide transactional updates, such as billing and account management.</li>
                                <li>Investigate security issues or comply with legal obligations.</li>
                            </ol>
                            <li>
                                <h5>Navigational Information</h5>
                                <p>Navigational Information, such as your IP address and location, is used to analyze and improve the website experience. This data may be combined with other Personal Information to provide personalized content.</p>
                            </li>
                            <li>
                                <h5>Payment Information</h5>
                                <p>We process payment information solely for completing transactions. Payment data is securely handled by our payment processors, who are not permitted to store or use the data for other purposes.</p>
                            </li>
                        </ol>

                        <h4>How We Share Information</h4>
                        <p>We may share Personal Information as follows:</p>
                        <ol style={{ marginLeft: '100px', marginTop: '0px' }}>
                            <li>
                                <b>Within Our Company: </b>
                                <p>Among Hitthepick.com entities and advisors for consistent service.</p>
                            </li>
                            <li>
                                <b>With Service Providers: </b>
                                <p>For data analysis, payment processing, or customer support.</p>
                            </li>
                            <li>
                                <b>With Marketing Partners: </b>
                                <p>Only with your consent, to provide relevant offers.</p>
                            </li>
                            <li>
                                <b>For Corporate Events: </b>
                                <p>In the case of mergers, acquisitions, or reorganizations.</p>
                            </li>
                            <li>
                                <b>For Legal Compliance: </b>
                                <p>If required by law or to protect safety and rights.</p>
                            </li>
                        </ol>

                        <h4>Data Retention</h4>
                        <p>We retain your Personal Information as long as needed for legitimate business purposes or to comply with legal obligations. Afterward, we securely delete or anonymize the data.</p>

                        <h4>Cookies and Similar Technologies</h4>
                        <ol style={{ marginLeft: '100px', marginTop: '0px' }}>
                            <li>
                                <b>Cookies </b>
                                <p>We and our partners use cookies and similar technologies to improve website functionality, track usage, and deliver personalized content. Learn more in our Cookie Policy</p>
                            </li>
                            <li>
                                <b>Advertising</b>
                                <p>We partner with ad networks that use cookies to display relevant ads based on your online activity. You may opt out of targeted advertising by visiting </p>
                            </li>
                        </ol>

                        <h4>Your Rights</h4>
                        <p>You may:</p>
                        <ol style={{ marginLeft: '100px', marginTop: '0px' }}>
                            <li>
                                <p>Request access to or correction of your data.</p>
                            </li>
                            <li>
                                <p>Withdraw consent to processing.</p>
                            </li>
                            <li>
                                <p>Request data deletion.</p>
                            </li>
                            <li>
                                <p>Opt out of marketing communications.</p>
                            </li>
                        </ol>
                        <p>For requests, contact hitthepick@gmail.com or mail us at:
                        Hitthepick.com</p>

                        <h4>State-Specific Privacy Rights</h4>
                        <p>We comply with privacy laws for residents of California, Virginia, Colorado, and other states with specific rights. Refer to the state-specific sections for detailed rights applicable to you.</p>

                        <h4>Questions</h4>
                        <p>For questions about this Privacy Policy, contact us</p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default PrivacyPolicies;
