import { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import Footer from "../component/layout/footer";
import Header from "../component/layout/header";
import PageHeader from "../component/layout/pageheader";

const title = "Register Now";

class SignUp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            regFName: "",
            regLName: "",
            regEmail: "",
            regPassword: "",
            regConPassword: "",
            error: "",
            success: "",
        };
    }

    handleSubmit = async (e) => {
        e.preventDefault();

        const { regFName, regLName, regEmail, regPassword, regConPassword } = this.state;

        // Validate input
        if (!regFName || !regLName || !regEmail || !regPassword || !regConPassword) {
            this.setState({ error: "All fields are required.", success: "" });
            return;
        }

        if (regPassword !== regConPassword) {
            this.setState({ error: "Passwords do not match.", success: "" });
            return;
        }

        try {
            // Backend API endpoint
            const baseURL = process.env.REACT_APP_BASE_URL;
            const response = await fetch(`${baseURL}/api/auth/register`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    firstname: regFName,
                    lastname: regLName,
                    email: regEmail,
                    password: regPassword,
                    role: 'user',
                }),
            });

            if (!response.ok) {
                throw new Error("Registration failed. Please try again.");
            } else {
                window.location.href = '/dashboard';
            }

            const data = await response.json();

            // Handle success response
            this.setState({
                success: "Registration successful. Please log in.",
                error: "",
                regFName: "",
                regLName: "",
                regEmail: "",
                regPassword: "",
                regConPassword: "",
            });
        } catch (error) {
            // Handle error response
            this.setState({ error: error.message, success: "" });
        }
    };

    render() {
        const { regFName, regLName, regEmail, regPassword, regConPassword, error, success } = this.state;

        return (
            <Fragment>
                <Header />
                <PageHeader title={"REGISTER"} curPage={"Sign Up"} />
                <div className="login-section padding-top padding-bottom">
                    <div className="container">
                        <div className="account-wrapper">
                            <h3 className="title">{title}</h3>
                            {error && <div className="error-message">{error}</div>}
                            {success && <div className="success-message">{success}</div>}
                            <form className="account-form" onSubmit={this.handleSubmit}>
                                <div className="form-group">
                                    <input
                                        type="text"
                                        name="regFName"
                                        id="item01"
                                        value={regFName}
                                        onChange={(e) => this.setState({ regFName: e.target.value })}
                                        placeholder="First Name *"
                                    />
                                </div>
                                <div className="form-group">
                                    <input
                                        type="text"
                                        name="regLName"
                                        id="item02"
                                        value={regLName}
                                        onChange={(e) => this.setState({ regLName: e.target.value })}
                                        placeholder="Last Name *"
                                    />
                                </div>
                                <div className="form-group">
                                    <input
                                        type="email"
                                        name="regEmail"
                                        id="item03"
                                        value={regEmail}
                                        onChange={(e) => this.setState({ regEmail: e.target.value })}
                                        placeholder="Your email *"
                                    />
                                </div>
                                <div className="form-group">
                                    <input
                                        type="password"
                                        name="regPassword"
                                        id="item04"
                                        value={regPassword}
                                        onChange={(e) => this.setState({ regPassword: e.target.value })}
                                        placeholder="Password *"
                                    />
                                </div>
                                <div className="form-group">
                                    <input
                                        type="password"
                                        name="regConPassword"
                                        id="item05"
                                        value={regConPassword}
                                        onChange={(e) => this.setState({ regConPassword: e.target.value })}
                                        placeholder="Confirm Password *"
                                    />
                                </div>
                                <div className="form-group">
                                    <button type="submit" className="d-block default-button">
                                        <span>Get Started Now</span>
                                    </button>
                                </div>
                            </form>
                            <div className="account-bottom">
                                <span className="d-block cate pt-10">
                                    Are you a member? <Link to="/login">Login</Link>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </Fragment>
        );
    }
}

export default SignUp;
