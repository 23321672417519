import { Component } from "react";

class HrShape extends Component {
    render() { 
        return (
            <div className="container">
                <hr className="m-0" />
            </div>
        );
    }
}
 
export default HrShape;