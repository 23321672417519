
const TermsOfServicesContent = () => {

    return (
        <section className="match-section padding-top padding-bottom" style={{backgroundImage: "url(/assets/images/match/bg-2.jpg)"}}>
            <div className="container">
                <div className="section-wrapper">
                    <div className="row g-4 justify-content-center collection-grid GameListStyle">
                        <p>Welcome to HitThePick.com. By accessing or using our services, you agree to comply with and be bound by the following terms and conditions. If you do not agree to these terms, you should not use our services. Please read these terms carefully.</p>

                        <h4>Introduction</h4>
                        <p>These Terms of Service (“Agreement”) govern your access to and use of HitThePick.com (the “Website”) and its associated services (collectively, the “Services”). By using the Services, you agree to be bound by these terms and our Privacy Policy.</p>

                        <h4>Eligibility</h4>
                        <p>To use the Services, you must be at least 18 years old, or at least 21 years old if you reside in the United States. By registering, you confirm that the information you provide is accurate, complete, and up to date.</p>

                        <h4>Registration</h4>
                        <p>To access certain features, you must register an account. During registration</p>
                        <ol style={{ marginLeft: '100px', marginTop: '0px' }}>
                            <li>Provide accurate and current information</li>
                            <li>Update your information promptly if there are changes.</li>
                            <li>Choose a secure password and maintain its confidentiality.
                            You are fully responsible for all activities conducted under your account and must notify us immediately of any unauthorized access or security breaches</li>
                        </ol>

                        <h4>Access Pass</h4>
                        <p>Your access to HitThePick.com content depends on the Access Pass you purchase:</p>
                        <ol>
                            <li>
                                <b>Evaluation Access Pass</b>
                                <p>Free accounts have limited access to content for evaluation purposes.</p>
                            </li>
                            <li>
                                <b>Partial Access Pass</b>
                                <p>Provides indefinite access to specific content for a fixed price.</p>
                            </li>
                            <li>
                                <b>Full Access Pass</b>
                                <p>Grants unlimited access to all content for the duration of the purchased term.
                                    The scope of access will be described at the time of purchase.
                                </p>
                            </li>
                        </ol>

                        <h4>Payment Terms</h4>
                        <p>You may purchase Access Passes using VISA, MasterCard, Discover, American Express, or PayPal. All transactions are processed in U.S. dollars. By completing your purchase, you authorize us to charge the specified amount to your payment method. Any fees associated with your payment, including bank or currency conversion fees, are your responsibility.</p>

                        <h4>Refund Policy</h4>
                        <p>Refunds are only available under the following conditions:</p>
                        <ol style={{ marginLeft: '100px', marginTop: '0px' }}>
                            <li>A written request is submitted within 10 days of purchase.</li>
                            <li>The server logs confirm no access to the content during this period.
                            Refunds exclude bank fees and commissions. At our sole discretion, store credit or exchanges may be offered.</li>
                        </ol>

                        <h4>Access to Content</h4>
                        <p>It is your responsibility to maintain access to your account. We are not liable for lost access due to:</p>
                        <ol style={{ marginLeft: '100px', marginTop: '0px' }}>
                            <li>Failure to contact us regarding account issues.</li>
                            <li>Internet connectivity problems or other issues beyond our control.</li>
                        </ol>

                        <h4>Password Security</h4>
                        <p>You are responsible for safeguarding your password. Sharing your password or account access is prohibited. We are not liable for unauthorized access caused by your failure to secure your credentials.</p>

                        <h4>Customer Service</h4>
                        <p>For errors or issues with the Services, contact us immediately through the customer support portal on our Website.</p>

                        <h4>Premium Picks Specific Terms</h4>
                        <p>By purchasing or using Premium Picks, you agree to the following additional terms:</p>
                        <ol>
                            <li>
                                <b>Past Performance Disclaimer</b>
                                <p>Past performance does not guarantee future results. Sports betting is speculative and high-risk. There is no guarantee of profit from our picks.</p>
                            </li>
                            <li>
                                <b>Line Consistency</b>
                                <p>Each pick is based on specific lines at the time of publication. Results are graded based on those lines, and discrepancies in your available lines do not qualify for a guarantee.</p>
                            </li>
                            <li>
                                <b>Guaranteed Picks</b>
                                <p>If a pick is labeled “Guaranteed” and does not win or show a profit:</p>
                            </li>
                            <ol style={{ marginLeft: '120px', marginTop: '0px' }}>
                                <li>You will receive credit for the purchase amount in your account.</li>
                                <li>Credit is not refundable or withdrawable and can only be used for future purchases on the Website.</li>
                            </ol>
                        </ol>

                        <h4>Indemnification</h4>
                        <p>You agree to indemnify and hold harmless HitThePick.com from any claims, damages, or expenses arising from your use of the Services or any breach of this Agreement.</p>

                        <h4>Limitations of Liability</h4>
                        <p>We are not liable for:</p>
                        <ol style={{ marginLeft: '100px', marginTop: '0px' }}>
                            <li>Your reliance on past performance or projections.</li>
                            <li>Financial losses incurred through sports betting.</li>
                            <li>Loss of access caused by issues beyond our control.</li>
                        </ol>

                        <h4>Prohibited Actions</h4>
                        <p>Unauthorized access to password-protected areas is prohibited and may result in legal action. Sharing access credentials or bypassing security measures is strictly forbidden.</p>

                        <h4>Amendments</h4>
                        <p>We reserve the right to modify this Agreement at any time. Changes will be posted on the Website, and continued use of the Services constitutes acceptance of the updated terms.</p>

                        <h4>Violations of Terms</h4>
                        <p>We may pursue all legal and equitable remedies for violations of this Agreement, including suspension or termination of your account.</p>

                        <h4>Contact Information</h4>
                        <p>For any questions or issues, please reach out to our customer service team via the support section on HitThePick.com.</p>

                        <h4>Acknowledgment</h4>
                        <p>By using HitThePick.com, you confirm that you have read, understood, and agree to these terms. If you do not agree, discontinue your use of the Website immediately.</p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default TermsOfServicesContent;
