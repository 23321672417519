import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Footer from "../component/layout/footer";
import Header from "../component/layout/header";
import PageHeader from "../component/layout/pageheader";

const ViewSubscription = () => {
    const { id } = useParams(); // Extract the subscription ID from the URL
    const [subscriptionDetails, setSubscriptionDetails] = useState(null);
    const [leaguePicks, setLeaguePicks] = useState([]); // Store picks for the leagues
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const token = localStorage.getItem("frontend_token");
    const baseURL = process.env.REACT_APP_BASE_URL;

    useEffect(() => {
        const fetchSubscriptionAndPicks = async () => {
            try {
                if (!token) {
                    window.location.href = "/login";
                    return;
                }

                const response = await fetch(`${baseURL}/api/user/subscription/${id}/league-picks`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });

                if (!response.ok) {
                    throw new Error("Failed to fetch subscription details and picks");
                }

                const data = await response.json();
                setSubscriptionDetails(data.subscription);
                setLeaguePicks(data.picks);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchSubscriptionAndPicks();
    }, [id]);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    if (!subscriptionDetails) {
        return <div>Subscription not found.</div>;
    }

    const { name, price, durationInDays, handicapper, leagues } = subscriptionDetails;

    return (
        <>
            <Header />
            <PageHeader title={"Subscription Details"} curPage={"Subscription Details"} />
            <section className="subscription-details padding-top padding-bottom">
                <div className="container">
                    <div className="section-wrapper">
                        <div className="details-item">
                            <h2>{name}</h2>
                            <p>
                                <strong>Price:</strong> ${price}
                            </p>
                            <p>
                                <strong>Duration:</strong> {durationInDays} days
                            </p>
                            {handicapper && handicapper.length > 0 ? (
                                <>
                                    <h3>Handicapper Information:</h3>
                                    <p>
                                        <strong>Name:</strong> {handicapper[0].firstname} {handicapper[0].lastname}
                                    </p>
                                    <p>
                                        <strong>Email:</strong> {handicapper[0].email}
                                    </p>
                                    <p>
                                        <strong>Status:</strong> {handicapper[0].status}
                                    </p>
                                </>
                            ) : (
                                <p>No handicapper information available.</p>
                            )}

                            <h3>Leagues Included:</h3>
                            {leagues && leagues.length > 0 ? (
                                <ul>
                                    {leagues.map((league) => (
                                        <li key={league._id} style={{ borderBottom: '1px solid white', marginBottom: '10px', marginTop: '10px' }}>
                                            <strong>Title:</strong> {league.title} <br />
                                            <strong>Group:</strong> {league.group} <br />
                                            <h4>Picks in this League:</h4>
                                            {leaguePicks.filter((pick) => pick.league === league._id).length > 0 ? (
                                                <ul>
                                                    {leaguePicks
                                                        .filter((pick) => pick.league === league._id)
                                                        .map((pick) => (
                                                            <li key={pick._id} style={{ paddingLeft: '15px', marginBottom: '10px' }}>
                                                                <strong>Pick Title:</strong> {pick.title} <br />
                                                                <strong>Units:</strong> {pick.units} <br />
                                                                <strong>Analysis:</strong> {pick.analysis} <br />
                                                            </li>
                                                        ))}
                                                </ul>
                                            ) : (
                                                <p>No picks are associated with this league.</p>
                                            )}
                                        </li>
                                    ))}
                                </ul>
                            ) : (
                                <p>No leagues are associated with this subscription.</p>
                            )}
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
};

export default ViewSubscription;
