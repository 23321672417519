import { useState } from "react";

const Popup = () => {
    const [showPopup, setShowPopup] = useState(true);

    const handleClose = () => {
        setShowPopup(false);
    };

    const handleSignup = () => {
        window.location.href = "/signup";
    };

    if (!showPopup) return null;

    return (
        <div style={popupStyles.overlay}>
            <div style={popupStyles.popup}>
                <button style={popupStyles.closeButton} onClick={handleClose}>
                    &times;
                </button>
                <h2 style={popupStyles.title}>🎉 GET $100 FREE SITE CREDIT ON SIGN UP! 🎉</h2>
                <p style={popupStyles.message}>
                    Click here to get <strong>$100 free site credits</strong> to get started!
                </p>
                <button className="default-button reverse-effect" onClick={handleSignup}>
                    <span>Sign Up Now</span>
                </button>
            </div>
        </div>
    );
};

// Styles for the popup
const popupStyles = {
    overlay: {
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 1000,
    },
    popup: {
        background: "#fff",
        borderRadius: "8px",
        padding: "20px",
        textAlign: "center",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
        position: "relative",
    },
    closeButton: {
        position: "absolute",
        top: "10px",
        right: "10px",
        background: "none",
        border: "none",
        fontSize: "20px",
        cursor: "pointer",
    },
    title: {
        margin: "0 0 10px",
        color: "#333",
    },
    message: {
        margin: "10px 0 20px",
        fontSize: "16px",
        color: "#555",
    },
    signupButton: {
        backgroundColor: "#007bff",
        color: "#fff",
        border: "none",
        borderRadius: "4px",
        padding: "10px 20px",
        cursor: "pointer",
        fontSize: "16px",
    },
};

export default Popup;
