import { Component, useState } from "react";
import { Link } from "react-router-dom";

const subtitle = "Anywhere, Anytime";
const title = "All matches schedule";


let MatchInfoListOne = [
    {
        imageone: 'assets/images/match/teamsm/teamsm-1.png',
        alt1: 'Charleston',
        imagetwo: 'assets/images/match/teamsm/teamsm-2.png',
        alt2: 'Tampa Bay Buccaneers',
        matchdate: '04  November 2024',
        matchtime: 'Time: 06: 15AM',
        btntextTwo: 'Prediction',
    },
    {
        imageone: 'assets/images/match/teamsm/teamsm-3.png',
        alt1: 'Kansas City Chiefs',
        imagetwo: 'assets/images/match/teamsm/teamsm-4.png',
        alt2: 'Milwaukee Bucks',
        matchdate: '05  November 2024',
        matchtime: 'Time: 05:10AM',
        btntextTwo: 'Prediction',
    },
    {
        imageone: 'assets/images/match/teamsm/teamsm-5.png',
        alt1: 'Cleveland Cavaliers',
        imagetwo: 'assets/images/match/teamsm/teamsm-6.png',
        alt2: 'Golden ST. Warriors',
        matchdate: '05  November 2024',
        matchtime: 'Time: 05:150AM',
        btntextTwo: 'Prediction',
    },
    {
        imageone: 'assets/images/match/teamsm/teamsm-7.png',
        alt1: 'Washington Wizards',
        imagetwo: 'assets/images/match/teamsm/teamsm-8.png',
        alt2: 'Los Angeles Lakers',
        matchdate: '05  November 2024',
        matchtime: 'Time: 05:30AM',
        btntextTwo: 'Prediction',
    },
    {
        imageone: 'assets/images/match/teamsm/teamsm-9.png',
        alt1: 'Detroit Pistons',
        imagetwo: 'assets/images/match/teamsm/teamsm-10.png',
        alt2: 'Atlanta Hawks',
        matchdate: '05  November 2024',
        matchtime: 'Time: 05: 45AM',
        btntextTwo: 'Prediction',
    },
]

class MatchSectionTwo extends Component {
    render() { 
        const filterItem = (categItem) => {
            const updateItems = MatchInfoListOne.filter((curElem) => {
                return curElem.catagory === categItem;
            });
            // setItems(updateItems);
        }
        return (
            <section className="match-section padding-top padding-bottom" style={{backgroundImage: "url(/assets/images/match/bg-2.jpg)"}}>
                <div className="container">
                    <div className="section-header">
                        <p>{subtitle}</p>
                        <h2>{title}</h2>
                    </div>
                    <div className="section-wrapper">
                        <ul className="collection-filter-button-group common-filter-button-group d-flex flex-wrap justify-content-center mb-5 text-uppercase">  
                            {/* <li onClick={() => setItems(MatchInfoListOne) }>All matches</li> */}
                            <li onClick={() => filterItem('collection-one') }>today’s matches</li>
                            <li onClick={() => filterItem('collection-two') }>upcoming matches</li>
                            <li onClick={() => filterItem('collection-three') }>matche results</li>
                        </ul>
                        <div className="row g-4 mb-5">
                            {MatchInfoListOne.map((val, i) => (
                                <div className="col-12" key={i}>
                                    <div className="match-item item-layer">
                                        <div className="match-inner">
                                            <div className="match-content bg-white">
                                                <div className="row gy-4 align-items-center justify-content-center">
                                                    <div className="col-xl-6 col-md-6 order-md-2">
                                                        <div className="match-game-team">
                                                            <ul className="match-team-list d-flex flex-wrap align-items-center justify-content-center">
                                                                <li className="match-team-thumb">
                                                                    <Link to="/team-single"><img src={val.imageone} alt={val.alt1} style={{ width: '80px' }} /></Link>
                                                                </li>
                                                                <li className="text-center">
                                                                    <img className="w-75 w-md-100" src="assets/images/match/vs.png" alt="vs" />
                                                                </li>
                                                                <li className="match-team-thumb">
                                                                    <Link to="/team-single"><img src={val.imagetwo} alt={val.alt2} style={{ width: '80px' }} /></Link>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-4 col-md-6 order-md-1">
                                                        <div className="match-game-info">
                                                            <h4><Link to="/team-single">{val.alt1 + " vs " +val.alt2 }</Link></h4>
                                                            <p className="d-flex flex-wrap justify-content-center  justify-content-md-start">
                                                                <span className="match-date">{val.matchdate} </span>
                                                                <span className="match-time">{val.matchtime}</span>
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-2 col-md-6 order-md-3">
                                                        <div className="match-game-social">
                                                            <ul className="match-social-list d-flex flex-wrap align-items-center justify-content-center justify-content-xl-start">
                                                                <li>
                                                                    <a href="#" className="default-button reverse-effect"><span>{val.btntextTwo}</span></a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
 
export default MatchSectionTwo;