import { Component } from "react";
import { Link } from "react-router-dom";

const title = "TODAY'S BIG MATCH";
const desc = "Time : 01:00PM   -  16 December 2024";

class BannerSection extends Component {
    render() { 
        return (
            <section className="banner-section" style={{ backgroundImage: "url(/assets/images/banner/bg.jpg)" }}>
                <div className="container">
                    <div className="">
                        <div className="banner-content text-center">
                            <h4 className="fw-normal theme-color mb-4">{title}</h4>
                            <p>{desc}</p>
                        </div>
                        <div className="banner-thumb d-flex flex-wrap justify-content-center justify-content-between align-items-center align-items-lg-end">
                            <div className="banner-thumb-img ml-xl-50-none">
                                <img src="assets/images/banner/montery.png" alt="banner-thumb" style={{ width: '300px' }} />
                            </div>
                            <div className="banner-thumb-vs">
                                <img src="assets/images/banner/vs.png" alt="banner-thumb" />
                            </div>
                            <div className="banner-thumb-img mr-xl-50-none">
                                <img src="assets/images/banner/america.png" alt="banner-thumb" style={{ width: '300px' }} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
 
export default BannerSection;