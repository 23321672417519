import Footer from "../component/layout/footer";
import Header from "../component/layout/header";
import PageHeader from "../component/layout/pageheader";
import { useAuth } from "../context/AuthContext";
import TermsOfServicesContent from "../component/section/termsofservicescontent";

const TermsOfServices = () => {
    const { user, logout } = useAuth();

    return (
        <>
            <Header />
            <PageHeader title={"Terms of service"} curPage={"Terms of service"} />
            <TermsOfServicesContent />
            <Footer />
        </>
    );
};

export default TermsOfServices;
