import Footer from "../component/layout/footer";
import Header from "../component/layout/header";
import SubscriptionList from "../component/section/subscriptionlist";
import PageHeader from "../component/layout/pageheader";
import { useAuth } from "../context/AuthContext";

const Subscriptions = () => {
    const { user, logout } = useAuth();

    return (
        <>
            <Header />
            <PageHeader title={"Subscriptions"} curPage={"Subscriptions"} />
            <SubscriptionList />
            <Footer />
        </>
    );
};

export default Subscriptions;
