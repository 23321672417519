import React, { useState, useEffect } from "react";
import axios from "axios";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";

const GameList = () => {
    const [items, setItems] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedItem, setSelectedItem] = useState(null); // Tracks the selected item for payment
    const [showPayPal, setShowPayPal] = useState(false); // Modal visibility
    const [loadingItemId, setLoadingItemId] = useState(null); // Tracks which button is loading

    const baseURL = process.env.REACT_APP_BASE_URL;

    useEffect(() => {
        const fetchGameList = async () => {
            try {
                const response = await axios.get(`${baseURL}/api/user/get-picks`);
                setItems(response.data);
                setIsLoading(false);
            } catch (error) {
                console.error("Error fetching game list:", error);
                setIsLoading(false);
            }
        };

        fetchGameList();
    }, []);

    const handlePurchase = (item) => {
        const userToken = localStorage.getItem("frontend_token"); // Check for token in localStorage

        if (!userToken) {
            // Redirect to login if not authenticated
            window.location.href = "/login";
            return;
        }

        // Proceed with purchase if authenticated
        setSelectedItem(item); // Set the item for payment
        setLoadingItemId(item._id); // Set the loading state for this button
        setShowPayPal(true); // Show the modal with PayPal button
    };

    const handleApprove = async (orderID) => {
        try {
            const userToken = localStorage.getItem("frontend_token"); // Get the token from localStorage

            const response = await axios.post(
                `${baseURL}/api/user/capture-order`,
                {
                    orderID,
                    type: "pick",
                    itemId: selectedItem._id,
                },
                {
                    headers: {
                        Authorization: `Bearer ${userToken}`, // Include token in the Authorization header
                    },
                }
            );

            alert("Payment successful!");
        } catch (error) {
            console.error("Error capturing PayPal order:", error);
        } finally {
            setShowPayPal(false);
            setLoadingItemId(null); // Reset the loading state
        }
    };

    const handleModalClose = () => {
        setShowPayPal(false);
        setLoadingItemId(null); // Reset loading state if modal is closed
    };

    if (isLoading) {
        return <div>Loading...</div>;
    }

    return (
        <PayPalScriptProvider options={{ "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID }}>
            <section className="collection-section padding-top padding-bottom">
                <div className="container">
                    <div className="section-header">
                        <h2>Premium Picks</h2>
                    </div>
                    <div className="section-wrapper">
                        <div className="row g-4 justify-content-center collection-grid GameListStyle">
                            {items.map((item) => {
                                const { _id, title, match, units, handicapperId } = item;
                                return (
                                    <div className="col-12" key={_id}>
                                        <div className="match-item item-layer">
                                            <div className="match-inner">
                                                <div className="match-content bg-white">
                                                    <div className="row gy-4 align-items-center justify-content-center">
                                                        <div
                                                            className="col-xl-2 col-md-2 match-game-info d-flex flex-wrap align-items-center justify-content-start"
                                                            style={{ gap: "30px" }}
                                                        >
                                                            <img
                                                                src={baseURL + "/uploads/handicappers/" + handicapperId.profileImage}
                                                                alt={handicapperId.firstname}
                                                                style={{
                                                                    width: "100px",
                                                                    borderRadius: "50%",
                                                                    height: "100px",
                                                                    objectFit: "cover",
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="col-xl-5 col-md-5 order-md-2">
                                                            <div className="match-game-team">
                                                                <ul className="match-team-list d-flex flex-wrap align-items-center justify-content-center">
                                                                    <li className="match-team-thumb">
                                                                        <h4 style={{ color: "black" }}>{match.homeTeam}</h4>
                                                                    </li>
                                                                    <li className="text-center">
                                                                        <img
                                                                            className="w-75 w-md-100"
                                                                            src="assets/images/match/vs.png"
                                                                            alt="vs"
                                                                        />
                                                                    </li>
                                                                    <li className="match-team-thumb">
                                                                        <h4 style={{ color: "black" }}>{match.awayTeam}</h4>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div className="col-xl-3 col-md-3 order-md-1">
                                                            <div className="match-game-info">
                                                                <h4 style={{ color: "black" }}>{title}</h4>
                                                                <p className="d-flex flex-wrap justify-content-center justify-content-md-start">
                                                                    <span className="match-date">{match?.commenceTime}</span>
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="col-xl-2 col-md-2 order-md-3">
                                                            <div className="match-game-social">
                                                                <ul className="match-social-list d-flex flex-wrap align-items-center justify-content-center">
                                                                    <li>
                                                                        <h4 style={{ color: "black" }}>
                                                                            ${Number(units * 100).toFixed(2)}
                                                                        </h4>
                                                                        <button
                                                                            className="default-button reverse-effect"
                                                                            onClick={() => handlePurchase(item)}
                                                                            disabled={loadingItemId === _id}
                                                                        >
                                                                            <span>
                                                                                {loadingItemId === _id
                                                                                    ? "Loading..."
                                                                                    : "Purchase"}
                                                                            </span>
                                                                        </button>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>

                {showPayPal && selectedItem && (
                    <div className="modal-overlay">
                        <div className="modal-content">
                            <button className="close-button" onClick={handleModalClose}>
                                &times;
                            </button>
                            <PayPalButtons
                                style={{
                                    layout: "vertical",
                                    color: "gold",
                                    shape: "rect",
                                    label: "paypal",
                                }}
                                fundingSource="paypal"
                                createOrder={(data, actions) => {
                                    return actions.order.create({
                                        purchase_units: [
                                            {
                                                amount: {
                                                    value: (selectedItem.units * 100).toFixed(2),
                                                },
                                            },
                                        ],
                                    });
                                }}
                                onApprove={(data) => handleApprove(data.orderID)}
                                onCancel={handleModalClose}
                                onError={(err) => {
                                    console.error("PayPal error:", err);
                                    handleModalClose();
                                }}
                            />
                        </div>
                    </div>
                )}
            </section>
        </PayPalScriptProvider>
    );
};

export default GameList;
