import Footer from "../component/layout/footer";
import Header from "../component/layout/header";
import PageHeader from "../component/layout/pageheader";
import { useAuth } from "../context/AuthContext";
import FreePicksList from "../component/section/freepickslist";

const FreePicks = () => {
    const { user, logout } = useAuth();

    return (
        <>
            <Header />
            <PageHeader title={"Free Picks"} curPage={"Free Picks"} />
            <FreePicksList />
            <Footer />
        </>
    );
};

export default FreePicks;
