import React, { useState, useEffect } from "react";
import axios from "axios";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";

const SubscriptionList = () => {
    const [items, setItems] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedItem, setSelectedItem] = useState(null); // Tracks the selected subscription for payment
    const [showPayPal, setShowPayPal] = useState(false); // Modal visibility
    const [loadingItemId, setLoadingItemId] = useState(null); // Tracks which button is loading

    const baseURL = process.env.REACT_APP_BASE_URL;

    useEffect(() => {
        const fetchSubscriptions = async () => {
            try {
                const response = await axios.get(`${baseURL}/api/user/get-subscriptions`);
                setItems(response.data);
                setIsLoading(false);
            } catch (error) {
                console.error("Error fetching subscriptions list:", error);
                setIsLoading(false);
            }
        };

        fetchSubscriptions();
    }, []);

    const handlePurchase = (item) => {
        const userToken = localStorage.getItem("frontend_token");
    
        if (!userToken) {
            window.location.href = "/login";
            return;
        }
        setSelectedItem(item);
        setLoadingItemId(item._id);
        setShowPayPal(true);
    };

    const handleApprove = async (orderID) => {
        try {
            const userToken = localStorage.getItem("frontend_token");

            const response = await axios.post(`${baseURL}/api/user/capture-order`, {
                orderID,
                type: "subscription",
                itemId: selectedItem._id,
            },
            {
                headers: {
                    Authorization: `Bearer ${userToken}`, // Include token in the Authorization header
                },
            }
            );

            alert("Payment successful!");
        } catch (error) {
            console.error("Error capturing PayPal order:", error);
        } finally {
            setShowPayPal(false);
            setLoadingItemId(null); // Reset the loading state
        }
    };

    const handleModalClose = () => {
        setShowPayPal(false);
        setLoadingItemId(null); // Reset loading state if modal is closed
    };

    if (isLoading) {
        return <div>Loading...</div>;
    }

    return (
        <PayPalScriptProvider options={{ "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID }}>
            <section className="collection-section padding-top padding-bottom">
                <div className="container">
                    <div className="section-header">
                        <h2>Subscription Plans</h2>
                    </div>
                    <div className="section-wrapper">
                        <div className="row g-4 justify-content-center collection-grid GameListStyle">
                            {items.map((item) => {
                                const { _id, name, handicapper, price } = item;
                                return (
                                    <div className="col-12" key={_id}>
                                        <div className="match-item item-layer">
                                            <div className="match-inner">
                                                <div className="match-content bg-white">
                                                    <div className="row gy-4 align-items-center justify-content-center">
                                                        <div
                                                            className="col-xl-2 col-md-2 match-game-info d-flex flex-wrap align-items-center justify-content-start"
                                                            style={{ gap: "30px" }}
                                                        >
                                                            <img
                                                                src={baseURL + "/uploads/handicappers/" + handicapper[0].profileImage}
                                                                alt={handicapper[0].firstname}
                                                                style={{
                                                                    width: "100px",
                                                                    borderRadius: "50%",
                                                                    height: "100px",
                                                                    objectFit: "cover",
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="col-xl-4 col-md-4 order-md-2">
                                                            <div className="match-game-team">
                                                                <ul className="match-team-list d-flex flex-wrap align-items-center justify-content-center">
                                                                    <li className="match-team-thumb">
                                                                        <h4 style={{ color: "black" }}>${Number(price).toFixed(2)}</h4>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div className="col-xl-4 col-md-6 order-md-1">
                                                            <div className="match-game-info">
                                                                <h4 style={{ color: "black" }}>{name}</h4>
                                                            </div>
                                                        </div>
                                                        <div className="col-xl-2 col-md-6 order-md-3">
                                                            <div className="match-game-social">
                                                                <ul className="match-social-list d-flex flex-wrap align-items-center justify-content-center">
                                                                    <li>
                                                                        <button
                                                                            className="default-button reverse-effect"
                                                                            onClick={() => handlePurchase(item)}
                                                                            disabled={loadingItemId === _id}
                                                                        >
                                                                            <span>
                                                                                {loadingItemId === _id
                                                                                    ? "Loading..."
                                                                                    : "Purchase"}
                                                                            </span>
                                                                        </button>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>

                {/* Modal for PayPal Button */}
                {showPayPal && selectedItem && (
                    <div className="modal-overlay">
                        <div className="modal-content">
                            <button className="close-button" onClick={handleModalClose}>
                                &times;
                            </button>
                            <PayPalButtons
                                style={{
                                    layout: "vertical",
                                    color: "gold",
                                    shape: "rect",
                                    label: "paypal",
                                }}
                                fundingSource="paypal"
                                createOrder={(data, actions) => {
                                    return actions.order.create({
                                        purchase_units: [
                                            {
                                                amount: {
                                                    value: Number(selectedItem.price).toFixed(2),
                                                },
                                            },
                                        ],
                                    });
                                }}
                                onApprove={(data) => handleApprove(data.orderID)}
                                onCancel={handleModalClose}
                                onError={(err) => {
                                    console.error("PayPal error:", err);
                                    handleModalClose();
                                }}
                            />
                        </div>
                    </div>
                )}
            </section>
        </PayPalScriptProvider>
    );
};

export default SubscriptionList;
