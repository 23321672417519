import Footer from "../component/layout/footer";
import Header from "../component/layout/header";
import PageHeader from "../component/layout/pageheader";
import GameList from "../component/section/gamelist";
import { useAuth } from "../context/AuthContext";

const Dashboard = () => {
    const { user, logout } = useAuth();

    return (
        <>
            <Header />
            <PageHeader title={"Pick"} curPage={"Picks"} />
            <GameList />
            <Footer />
        </>
    );
};

export default Dashboard;
