import { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import Footer from "../component/layout/footer";
import Header from "../component/layout/header";
import PageHeader from "../component/layout/pageheader";

const ViewPick = () => {
    const { id } = useParams(); // Extract id from the URL
    const [pickDetails, setPickDetails] = useState({ title: "", desc: "", btnText: "Go Home" });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const token = localStorage.getItem("frontend_token");
    const baseURL = process.env.REACT_APP_BASE_URL;

    useEffect(() => {
        const fetchPickDetails = async () => {
            try {
                if(token) {
                    const response = await fetch(`${baseURL}/api/user/pick/${id}`);
                    if (!response.ok) {
                        throw new Error("Failed to fetch pick details");
                    }
                    const data = await response.json();
                    setPickDetails({
                        title: data[0].title || "Pick Not Found",
                        units: data[0].units || "0",
                        analysis: data[0].analysis || "Details are unavailable.",
                        homeTeam: data[0].match.homeTeam || "-",
                        awayTeam: data[0].match.awayTeam || "-",
                    });
                } else {
                    window.location.href = '/login';
                }
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchPickDetails();
    }, [id]);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    return (
        <>
            <Header />
            <PageHeader title={"Pick"} curPage={"Pick"} />
            <section className="fore-zero padding-top padding-bottom">
                <div className="container">
                    <div className="section-wrapper">
                        <div className="zero-item">
                            <div className="zero-content">
                                <h2>{pickDetails.title}</h2>
                                <p>
                                    Units: {pickDetails.units}
                                </p>
                                <p>
                                    Home Team: {pickDetails.homeTeam}
                                </p>
                                <p>
                                    Away Team: {pickDetails.awayTeam}
                                </p>
                                <p>
                                    Units: {pickDetails.units}
                                </p>
                                <p>
                                    Analysis: {pickDetails.analysis}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
};

export default ViewPick;
