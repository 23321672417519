import Footer from "../component/layout/footer";
import Header from "../component/layout/header";
import PageHeader from "../component/layout/pageheader";
import PackagesList from "../component/section/packageslist";
import { useAuth } from "../context/AuthContext";

const Packages = () => {
    const { user, logout } = useAuth();

    return (
        <>
            <Header />
            <PageHeader title={"Packages"} curPage={"Packages"} />
            <PackagesList />
            <Footer />
        </>
    );
};

export default Packages;
