import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

const Leaderboard = () => {
    const [handicappers, setHandicappers] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const baseURL = process.env.REACT_APP_BASE_URL;

    useEffect(() => {
        const fetchHandicappers = async () => {
            try {
                const response = await axios.get(`${baseURL}/api/user/get-handicappers`);
                setHandicappers(response.data);
                setIsLoading(false);
            } catch (error) {
                console.error("Error fetching handicappers:", error);
                setIsLoading(false);
            }
        };

        fetchHandicappers();
    }, []);

    if (isLoading) {
        return <div>Loading...</div>;
    }

    return (
        <section
            className="match-section padding-top padding-bottom"
            style={{ backgroundImage: "url(/assets/images/match/bg-2.jpg)" }}
        >
            <div className="container">
                <div className="section-header">
                    <h2>Leaderboard</h2>
                </div>
                <div className="section-wrapper">
                    <div className="row g-4 justify-content-center collection-grid">
                        {handicappers.map((handicapper) => (
                            <div className="col-6" key={handicapper.id}>
                                <div className="match-item item-layer">
                                    <div className="match-inner">
                                        <div className="match-content bg-white">
                                            <div className="row gy-4 align-items-center justify-content-center">
                                                <div className="col-xl-6 col-md-6 order-md-2">
                                                    <div className="match-game-team">
                                                        <ul className="match-team-list d-flex flex-wrap align-items-center justify-content-center">
                                                            <li className="match-team-thumb">
                                                                <h6 style={{ color: "black" }}>W/L</h6>
                                                                <p style={{ color: "black" }}>
                                                                    0/0
                                                                </p>
                                                            </li>
                                                            <li className="match-team-thumb">
                                                                <h6 style={{ color: "black" }}>Winning Rate</h6>
                                                                <p style={{ color: "black" }}>
                                                                    0%
                                                                </p>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div className="col-12 text-center">
                                                        <Link
                                                            to={`/handicapper/${handicapper._id}`}
                                                            className="default-button reverse-effect"
                                                        >
                                                            <span>View</span>
                                                        </Link> &nbsp;
                                                        {
                                                            handicapper._id==="67598da0622b0777aabad120" ? (
                                                            <Link
                                                                to="https://x.com/WryCape?t=SaMnJrIIPAV54tLBO6Km6w&s=09"
                                                                className="default-button reverse-effect"
                                                            >
                                                                <span>X</span>
                                                            </Link>
                                                            ) : ""
                                                        }
                                                    </div>
                                                </div>
                                                <div className="col-xl-4 col-md-4 order-md-1">
                                                    <div
                                                        className="match-game-info d-flex flex-wrap align-items-center justify-content-start"
                                                        style={{ gap: "30px" }}
                                                    >
                                                        <img
                                                            src={baseURL + "/uploads/handicappers/" + handicapper.profileImage}
                                                            alt={handicapper.firstname}
                                                            style={{
                                                                width: "100px",
                                                                borderRadius: "50%",
                                                                height: "100px",
                                                                objectFit: "cover",
                                                            }}
                                                        />
                                                        <h4 style={{ color: "black" }}>{handicapper.firstname + " " + handicapper.lastname}</h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Leaderboard;
