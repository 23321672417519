import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";

const PurchasedItems = () => {
    const [items, setItems] = useState({ picks: [], packages: [], subscriptions: [] });
    const [isLoading, setIsLoading] = useState(true);
    const { user } = useAuth(); 

    const baseURL = process.env.REACT_APP_BASE_URL;

    useEffect(() => {
        const fetchPurchasedItems = async () => {
            try {
                const userToken = localStorage.getItem("frontend_token"); // Get token from localStorage

                // Fetch purchased picks
                const picksResponse = await axios.get(`${baseURL}/api/user/purchased-picks`, {
                    headers: { Authorization: `Bearer ${userToken}` },
                });

                // Fetch purchased packages
                const packagesResponse = await axios.get(`${baseURL}/api/user/purchased-packages`, {
                    headers: { Authorization: `Bearer ${userToken}` },
                });

                // Fetch purchased subscriptions
                const subscriptionsResponse = await axios.get(`${baseURL}/api/user/purchased-subscriptions`, {
                    headers: { Authorization: `Bearer ${userToken}` },
                });

                // Update state with purchased items
                setItems({
                    picks: picksResponse.data.picks || [],
                    packages: packagesResponse.data.packages || [],
                    subscriptions: subscriptionsResponse.data.subscriptions || [],
                });

                setIsLoading(false);
            } catch (error) {
                console.error("Error fetching purchased items:", error);
                setIsLoading(false);
            }
        };

        fetchPurchasedItems();
    }, []);

    if (isLoading) {
        return <div>Loading...</div>;
    }

    return (
        <section className="collection-section padding-top padding-bottom">
            <div className="container">
                <div className="section-header">
                    <h2>Balance: {user.balance}</h2>
                    <h2>Your Purchased Items</h2>
                </div>
                <div className="section-wrapper">
                    <div className="row g-4 justify-content-center">

                        {/* Display Purchased Picks */}
                        {items.picks.length > 0 && (
                            <div className="col-12">
                                <h3>Purchased Picks</h3>
                                <div className="row g-4">
                                    {items.picks.map((pick) => (
                                        <div className="col-12" key={pick._id}>
                                            <div className="match-item item-layer">
                                                <div className="match-inner">
                                                    <div className="match-content bg-white">
                                                        <div className="row gy-4 align-items-center justify-content-center">
                                                            <div className="col-xl-6 col-md-6 order-md-2">
                                                                <div className="match-game-team">
                                                                    <ul className="match-team-list d-flex flex-wrap align-items-center justify-content-center">
                                                                        <li className="match-team-thumb">
                                                                            <p style={{ color: 'black' }}>{pick.pickId.analysis}</p>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-4 col-md-6 order-md-1">
                                                                <div className="match-game-info">
                                                                    <h4 style={{ color: 'black' }}>{pick.pickId.league}</h4>
                                                                    <p className="d-flex flex-wrap justify-content-center justify-content-md-start">
                                                                        <span className="match-date">Units: {pick.pickId.units} </span>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-2 col-md-6 order-md-3">
                                                                <div className="match-game-social">
                                                                    <ul className="match-social-list d-flex flex-wrap align-items-center justify-content-center justify-content-xl-start">
                                                                        <li>
                                                                            <Link to={`/pick/${pick.pickId._id}`} className="default-button reverse-effect"><span>View</span></Link>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}

                        {/* Display Purchased Packages */}
                        {items.packages.length > 0 && (
                            <div className="col-12">
                                <h3>Purchased Packages</h3>
                                <div className="row g-4">
                                    {items.packages.map((pkg) => (
                                        <div className="col-12" key={pkg._id}>
                                            <div className="match-item item-layer">
                                                <div className="match-inner">
                                                    <div className="match-content bg-white">
                                                        <div className="row gy-4 align-items-center justify-content-center">
                                                            <div className="col-xl-6 col-md-6 order-md-2">
                                                                <div className="match-game-team">
                                                                    <ul className="match-team-list d-flex flex-wrap align-items-center justify-content-center">
                                                                        <li className="match-team-thumb">
                                                                            <p style={{ color: 'black' }}>Picks Included: {pkg.packageId.picks.length}</p>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-4 col-md-6 order-md-1">
                                                                <div className="match-game-info">
                                                                    <h4 style={{ color: 'black' }}>{pkg.packageId.name}</h4>
                                                                    <p className="d-flex flex-wrap justify-content-center justify-content-md-start">
                                                                        <span className="match-date">Price: {pkg.packageId.price} </span>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-2 col-md-6 order-md-3">
                                                                <div className="match-game-social">
                                                                    <ul className="match-social-list d-flex flex-wrap align-items-center justify-content-center justify-content-xl-start">
                                                                        <li>
                                                                            <Link to={`/package/${pkg.packageId._id}`} className="default-button reverse-effect"><span>View</span></Link>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}

                        {/* Display Purchased Subscriptions */}
                        {items.subscriptions.length > 0 && (
                            <div className="col-12">
                                <h3>Purchased Subscriptions</h3>
                                <div className="row g-4">
                                    {items.subscriptions.map((subscription) => (
                                        <div className="col-12" key={subscription._id}>
                                        <div className="match-item item-layer">
                                            <div className="match-inner">
                                                <div className="match-content bg-white">
                                                    <div className="row gy-4 align-items-center justify-content-center">
                                                        <div className="col-xl-6 col-md-6 order-md-2">
                                                            <div className="match-game-team">
                                                                <ul className="match-team-list d-flex flex-wrap align-items-center justify-content-center">
                                                                    <li className="match-team-thumb">
                                                                        <p style={{ color: 'black' }}><strong>Duration:</strong> {subscription.subscriptionId.durationInDays} days</p>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div className="col-xl-4 col-md-6 order-md-1">
                                                            <div className="match-game-info">
                                                                <h4 style={{ color: 'black' }}>{subscription.subscriptionId.name}</h4>
                                                                <p className="d-flex flex-wrap justify-content-center justify-content-md-start">
                                                                    <span className="match-date">Price: {subscription.subscriptionId.price} </span>
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="col-xl-2 col-md-6 order-md-3">
                                                            <div className="match-game-social">
                                                                <ul className="match-social-list d-flex flex-wrap align-items-center justify-content-center justify-content-xl-start">
                                                                    <li>
                                                                        <Link to={`/subscription/${subscription.subscriptionId._id}`} className="default-button reverse-effect"><span>View</span></Link>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    ))}
                                </div>
                            </div>
                        )}

                        {/* No Purchased Items */}
                        {items.picks.length === 0 &&
                            items.packages.length === 0 &&
                            items.subscriptions.length === 0 && (
                                <div className="col-12">
                                    <p>You have not purchased any items yet.</p>
                                </div>
                            )}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default PurchasedItems;
