import { Component } from "react";
import { Link } from "react-router-dom";

const newsTitle = "Quick Links";
const desc = "Your Winning Edge in Sports Betting! Discover expert insights, premium picks, and the strategies you need to dominate the game. Whether you're a seasoned bettor or just starting out, HitThePick delivers the tools to turn every play into a winning one.";

class Footer extends Component {
    constructor(props){
        super(props);
        this.state = {
            newsName: '',
            newsEmail: '',
        };
    }
    render() { 
        return (
            <footer className="footer-section">
                <div className="footer-middle padding-top padding-bottom" style={{backgroundImage: "url(/assets/images/footer/bg.jpg)"}}>
                    <div className="container">
                        <div className="row padding-lg-top">
                            <div className="col-lg-6 col-md-6 col-12">
                                <div className="footer-middle-item-wrapper">
                                    <div className="footer-middle-item mb-lg-0">
                                        <div className="fm-item-title mb-4">
                                            <img src="../../assets/images/logo/logo.png" alt="logo" width={200} />
                                        </div>
                                        <div className="fm-item-content">
                                            <p className="mb-4">{desc}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-2 col-md-2 col-12">
                                <div className="footer-middle-item-wrapper">
                                    <div className="footer-middle-item-3 mb-lg-0">
                                        <div className="fm-item-title">
                                            <h4>{newsTitle}</h4>
                                        </div>
                                        <div className="fm-item-content">
                                            <ul>
                                                <li className="d-flex flex-column gap-2">
                                                    <Link to={'/'}>Home</Link>
                                                    <Link to={'/terms-of-service'}>Terms of Service</Link>
                                                    <Link to={'/privacy-policy'}>Privacy Policy</Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-12">
                                <div className="footer-middle-item-wrapper">
                                    <div className="footer-middle-item-3 mb-lg-0">
                                        <div className="fm-item-title">
                                            <h4>Contact Us</h4>
                                        </div>
                                        <div className="fm-item-content">
                                            <ul>
                                                <li className="d-flex flex-row align-items-center gap-2 mb-4">
                                                    <i className="icofont-email"></i> hitthepicksports@gmail.com
                                                </li>
                                                <li className="d-flex flex-row align-items-center gap-2 mb-4">
                                                <i className="icofont-pin"></i> 2710 Conejo Canyon Ct
                                                    Thousand Oaks, CA, 91362
                                                </li>
                                                <li className="d-flex flex-row align-items-center gap-2 mb-4">
                                                <i className="icofont-phone"></i> +1 (866) 566-09192
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-bottom">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="footer-bottom-content text-center">
                                    <p>&copy;2024 <Link to="/">Hit The Pick</Link> - All rights reserved.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        );
    }
}
 
export default Footer;