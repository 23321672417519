import Footer from "../component/layout/footer";
import Header from "../component/layout/header";
import PageHeader from "../component/layout/pageheader";
import { useAuth } from "../context/AuthContext";
import PrivacyPolicies from "../component/section/privacypolicies";

const PrivacyPoliciesPage = () => {
    const { user, logout } = useAuth();

    return (
        <>
            <Header />
            <PageHeader title={"Privacy Policy"} curPage={"Privacy Policy"} />
            <PrivacyPolicies />
            <Footer />
        </>
    );
};

export default PrivacyPoliciesPage;
