import { Component, Fragment } from "react";
import Footer from "../component/layout/footer";
import Header from "../component/layout/header";

import BannerSection from "../component/section/banner";
import GameList from "../component/section/gamelist";
import Leaderboard from "../component/section/leaderboard";
import Popup from "./popup";


class HomePage extends Component {
    render() { 
        return (
            <Fragment>
                <Popup />
                <Header />
                <BannerSection />
                <GameList />
                <Leaderboard />
                <Footer />
            </Fragment>
        );
    }
}
 
export default HomePage;